import { css } from '@emotion/core';
import React from 'react';
import { smUp } from '../styles/breakpoints';
import COLORS from '../styles/colors';

const styles = css`
	background-color: ${COLORS.secondaryLight};
	padding: 40px 0 50px;
	margin-bottom: 50px;
	position: relative;
	&::after {
		content: '';
		position: absolute;
		top: 100%;
		left: 35px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 50px 50px 0 0;
		border-color: ${COLORS.secondaryLight} transparent transparent transparent;
		margin: -10px 0 0 0;
		transform: scaleX(${80 / 50});
	}
	*:last-child {
		margin-bottom: 0;
	}
	@media ${smUp} {
		margin-bottom: 90px;
		&::after {
			left: 50%;
			margin-left: calc(-31rem + 40px);
		}
	}
`;

const IntroTitleBox = ({ children }) => (
	<div css={styles}>
		{children}
	</div>
);

export default IntroTitleBox;
