import React from 'react';
import MainLayout from '../layouts/MainLayout';
import Container from '../components/layout/Container';
import IntroTitleBox from '../components/IntroTitleBox';
import MainTitle from '../components/MainTitle';

export default () => (
	<MainLayout>
		<IntroTitleBox>
			<Container>
				<MainTitle tag="h1">
					404 - Stránka nenalezena
				</MainTitle>
			</Container>
		</IntroTitleBox>
	</MainLayout>
);
